//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        adaptiveHeight: true,
      }
    }
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
}
